<template>
  <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 2% 0  2%;">
    <el-form-item label="三方供应商编码">
      <el-input placeholder="请输入供应商编码" v-model="data.supplierCode" clearable></el-input>
    </el-form-item>
    <el-form-item label="三方供应商名称">
      <el-input placeholder="请输入供应商名称" v-model="data.supplierName" clearable></el-input>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        v-model="data.regionCodeList"
        :options="areaOptions"
        clearable
        placeholder="请选择门店区域"
        filterable
        @change="changeArea()"
        :show-all-levels="false"
        collapse-tags
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCodeList"
        :options="data.shopLists"
        placeholder="请选择门店"
        class="search-select userSelect"
        style="width: 300px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item label="空瓶商品名称">
      <el-input placeholder="请输入空瓶商品名称" v-model="data.materielName" clearable></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="queryShops">搜索</el-button>
      <el-button @click="resetParams">
        重置
      </el-button>
      <el-button @click="addShop" v-if="data.authMenus.add">
        新增
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column show-overflow-tooltip label="序号" align="center" type="index" :index="indexfn" width="100" />
    <el-table-column show-overflow-tooltip prop="supplierCode" label="三方供应商编码" align="center" />
    <el-table-column show-overflow-tooltip prop="supplierName" label="三方供应商名称" align="center" />
    <el-table-column show-overflow-tooltip prop="emptyGoodsNumber" label="供货空瓶商品数量" align="center" />
    <el-table-column show-overflow-tooltip prop="supplierStoreNumber" label="供货门店数量" align="center" />
    <el-table-column show-overflow-tooltip prop="supplierStatusDesc" label="供应商状态" align="center" />
    <el-table-column show-overflow-tooltip prop="frozenStateDesc" label="冻结状态" align="center" />
    <el-table-column show-overflow-tooltip prop="updateTime" label="最近同步时间" align="center" />
    <el-table-column label="操作" width="350" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="showDetail(5, scope.row)">通联商户号 </el-button>
        <el-button type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.update">供货空瓶商品</el-button>
        <el-button type="text" @click="showDetail(4, scope.row)" v-if="data.authMenus.update"> 供货门店</el-button>
        <el-button type="text" @click="showDetail(1, scope.row)">详情</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddProvider
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :needEditMsg="data.needEditMsg"
    @update-dialogVisible="updateDialogVisible"
    :shopLists="shopLists"
    :isEdit="data.isEdit"
  ></AddProvider>

  <!--  通联商户号-->
  <el-dialog title="设置通联商户号" v-model="data.merchantDialogVisible" width="600px" class="dialog-form">
    <el-form label-width="80px" class="dialog-form" :model="data.syncData">
      <el-form-item label="通联商户号" class="is-required">
        <el-input placeholder="请输入通联商户号" v-model="data.syncData.merchantNo" clearable></el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: center;">
      <el-button type="primary" @click="handleSureSync">确 定</el-button>
      <el-button @click="data.merchantDialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import commonApi from '@/api'
import operationAuth from '@/lin/util/operationAuth.js'
import clearSelect from '@/lin/util/clearSelect2.js'
import _ from 'lodash'
import { reactive, onMounted, toRaw, computed, nextTick, ref } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import AddProvider from './AddProvider.vue'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'children',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  //获取门店区域
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaOptions.value = getTreeData(res.data)
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

const data = reactive({
  authMenus: {},
  storeCodeList: [],
  areaCode: [],
  regionCodeList: [],
  supplierName: undefined,
  supplierCode: undefined,
  materielName: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  dialogVisible: false,
  needEditMsg: {},
  isEdit: false,
  shopLists: [],
  articleList: [],
  skuCodeList: [],
  loading: false,
  merchantDialogVisible: false,
  syncData: {},
})

const route = useRoute()
const router = useRouter()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryShops()
  getShops()
  getArticles()
  getAreas()
})

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const getArticles = () => {
  API.selectProductAll({ type: 1 }).then(res => {
    if (res.code === '200') {
      data.articleList = res.data.map(item => ({
        value: item.code,
        label: item.name,
      }))
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const getShops = () => {
  let params = {}
  if (data.areaCode.length > 0) {
    params.withInOrgans = data.areaCode
  }
  commonApi.getNewShop(params).then(res => {
    if (res.code === '200') {
      data.shopLists = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const resetParams = () => {
  data.supplierName = undefined
  data.supplierCode = undefined
  data.materielName = undefined
  data.regionCodeList = []
  data.areaCode = []
  data.storeCodeList = []
  nextTick(() => {
    clearSelect('userSelect')
    clearSelect('userSelect1')
    getShops()
  })
  queryShops()
}

const handleSureSync = async () => {
  if (!data.syncData.merchantNo) {
    ElMessage.warning('请输入通联商户号')
    return
  }
  let params = {
    merchantNo: data.syncData.merchantNo,
    supplierCode: data.syncData.supplierCode,
    supplierName: data.syncData.supplierName,
  }
  let res = await API.updateMerchant(params)
  if (res.code == '200') {
    ElMessage.success('修改成功')
    data.merchantDialogVisible = false
  } else {
    ElMessage.error(res.msg)
  }
}

// 获取门店列表数据
const searchList = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let query = {
    materielName: data.materielName,
    supplierName: data.supplierName,
    supplierCode: data.supplierCode,
    storeCodeList: data.storeCodeList,
    regionCodeList: data.areaCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }
  API.queryTbThirdPartySupplierPage(query)
    .then(res => {
      if (res.code == '200') {
        data.tableData = res.data?.list || []
        data.total = res.data?.total || 0
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}
const queryShops = () => {
  data.currentPage = 1
  searchList()
}

//添加
const addShop = () => {
  data.needEditMsg = {
    bankList: [],
  }
  data.isEdit = true
  data.dialogVisible = true
}
const toArticleManager = rowMsg => {
  let params = {
    supplierName: rowMsg.supplierName,
    code: rowMsg.supplierCode,
  }
  sessionStorage.setItem('purchase-info', JSON.stringify(params))
  router.push(`/purchase-manager-new/ArticleManager`)
}
const toShopManager = rowMsg => {
  let params = {
    supplierName: rowMsg.supplierName,
    code: rowMsg.supplierCode,
  }
  sessionStorage.setItem('purchase-info', JSON.stringify(params))
  router.push(`/purchase-manager-new/ShopManager`)
}

const showMsg = rowMsg => {
  API.queryTbThirdPartySupplierByCodeDetail({ supplierCode: rowMsg.supplierCode }).then(res => {
    if (res?.code == 200) {
      data.needEditMsg = res.data
      data.dialogVisible = true
    }
  })
}

//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showMsg(rowMsg)
      break
    case 3:
      toArticleManager(rowMsg)
      break
    case 4:
      toShopManager(rowMsg)
      break
    case 5:
      queryMerchantNo(rowMsg)
      break
  }
}

const queryMerchantNo = async rowMsg => {
  let res = await API.queryMerchantNo({
    supplierCode: rowMsg.supplierCode,
  })
  if (res.code == 200) {
    data.merchantDialogVisible = true
    data.syncData = {
      merchantNo: res.data?.merchantNo || '',
      supplierCode: rowMsg.supplierCode,
      supplierName: rowMsg.supplierName,
    }
  } else {
    ElMessage.error(res.msg)
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryShops()
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchList()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchList()
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
